*:not(.t-font-icon, .k-icon) {
  font-family: "Akkurat", system-ui, sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Akkurat";
  src: url("/static/fonts/Akkurat.eot");
  src: url("/static/fonts/Akkurat.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/Akkurat.woff2") format("woff2"), url("/static/fonts/Akkurat.woff") format("woff"),
    url("/static/fonts/Akkurat.ttf") format("ttf"), url("/static/fonts/Akkurat.svg#Akkurat") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("/static/fonts/CircularStd-Book.eot");
  src: url("/static/fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/CircularStd-Book.woff2") format("woff2"),
    url("/static/fonts/CircularStd-Book.woff") format("woff"),
    url("/static/fonts/CircularStd-Book.ttf") format("truetype"),
    url("/static/fonts/CircularStd-Book.svg#CircularStd-Book") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("/static/fonts/CircularStd-MediumItalic.eot");
  src: url("/static/fonts/CircularStd-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/CircularStd-MediumItalic.woff2") format("woff2"),
    url("/static/fonts/CircularStd-MediumItalic.woff") format("woff"),
    url("/static/fonts/CircularStd-MediumItalic.ttf") format("truetype"),
    url("/static/fonts/CircularStd-MediumItalic.svg#CircularStd-MediumItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("/static/fonts/CircularStd-BookItalic.eot");
  src: url("/static/fonts/CircularStd-BookItalic.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/CircularStd-BookItalic.woff2") format("woff2"),
    url("/static/fonts/CircularStd-BookItalic.woff") format("woff"),
    url("/static/fonts/CircularStd-BookItalic.ttf") format("truetype"),
    url("/static/fonts/CircularStd-BookItalic.svg#CircularStd-BookItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("/static/fonts/CircularStd-BlackItalic.eot");
  src: url("/static/fonts/CircularStd-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/CircularStd-BlackItalic.woff2") format("woff2"),
    url("/static/fonts/CircularStd-BlackItalic.woff") format("woff"),
    url("/static/fonts/CircularStd-BlackItalic.ttf") format("truetype"),
    url("/static/fonts/CircularStd-BlackItalic.svg#CircularStd-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Circular-Std-Bold";
  src: url("/static/fonts/CircularStd-Bold.eot");
  src: url("/static/fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/CircularStd-Bold.woff2") format("woff2"),
    url("/static/fonts/CircularStd-Bold.woff") format("woff"),
    url("/static/fonts/CircularStd-Bold.ttf") format("truetype"),
    url("/static/fonts/CircularStd-Bold.svg#CircularStd-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("/static/fonts/CircularStd-Black.eot");
  src: url("/static/fonts/CircularStd-Black.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/CircularStd-Black.woff2") format("woff2"),
    url("/static/fonts/CircularStd-Black.woff") format("woff"),
    url("/static/fonts/CircularStd-Black.ttf") format("truetype"),
    url("/static/fonts/CircularStd-Black.svg#CircularStd-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Circular-Std-Medium";
  src: url("/static/fonts/CircularStd-Medium.eot");
  src: url("/static/fonts/CircularStd-Medium.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/CircularStd-Medium.woff2") format("woff2"),
    url("/static/fonts/CircularStd-Medium.woff") format("woff"),
    url("/static/fonts/CircularStd-Medium.ttf") format("truetype"),
    url("/static/fonts/CircularStd-Medium.svg#CircularStd-Medium") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "GraphikBold";
  src: url("/static/fonts/Graphik-Bold.otf");
}
@font-face {
  font-family: "GraphikMed";
  src: url("/static/fonts/Graphik-Semibold.ttf");
}
.switch-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.switch-wrapper .ParamSwitchContainer {
  margin-right: 16px;
  margin-bottom: 16px;
}

